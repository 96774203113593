import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBars, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Search from "../../components/Search";
import { Link } from "react-router-dom";
export default function Header(props) {
  const navigate = useNavigate();

  const { pageTitle, isBackVisible } = props;
  const backHandler = () => {
    navigate(-1)
  }
  const [showMenu, setShowMenu] = useState(false);

  const showMenuHandler = () => {
    setShowMenu(true);
  }
  const closeMenuHandler = () => {
    setShowMenu(false);
  }

  return (
    <header className="py-2 border-bottom">
      <div className="common-container">
        <div className="d-flex align-items-center">
          {isBackVisible ? <> <button className="back-btn me-3 border-0 bg-transparent" onClick={backHandler}>
            <FontAwesomeIcon icon={faAngleLeft} className="h2 mb-0" />
          </button></> : null}
          <h3>{pageTitle} </h3>
          <button className="bg-light-2 rounded-circle  p-2 border-0 ms-auto btn-menu-opener" onClick={showMenuHandler}>
            <FontAwesomeIcon icon={faUser} className="h4 mb-0" />
            {/* <FontAwesomeIcon icon={faBars} className="h2 mb-0" /> */}
          </button>

          <div className={`primary-menu-wrapper ${showMenu ? "active" : ""}`}>
            <div className="inner d-flex flex-column bg-white">
              <button className="btn-menu-close" onClick={closeMenuHandler}>
                <FontAwesomeIcon icon={faTimes} className="h2 mb-0" />

              </button>
              <div className="primary-menu-wrapper-inner">
                <div className="top text-center border-bottom px-2 py-4">
                  <div className="logo"><img src={"../assets/images/subway.png"} alt="vendor-logo" /></div>
                  <h4>SubWay Rolls</h4>
                </div>
              </div>
              <ul className="primary-menu px-2">
                <li><button onClick={() => { navigate("/") }}>Home</button></li>
                <li><button onClick={() => { navigate("/previous-orders") }}>My Orders</button></li>
                <li><button onClick={() => { navigate("/profile") }}    >Settings</button></li>

              </ul>
              <div className="bottom bg-light mt-auto py-2 px-2 font-small text-primary font-weight-bold text-center">
                Powered By : <a href="http://streetdine.com/" target="_blank">StreetDine.com</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
  );
}
