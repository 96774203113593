import React from "react";
import { Form, Carousel, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
export const SignIn = () => {
  return (
    <div className="common-login">
      <div className="common-container">
        <div className="inner d-flex flex-column">
          <div className="top">
            <img src={"/assets/images/login/slider-1.svg"} alt="" />
            <h3 className="text-primary title">Login</h3>

          </div>
          <div className="bottom flex-grow-1 d-flex flex-column">

            <div className="form-group mb-4">
              <Form.Group className="mb-4">
                <label htmlFor="">Name<span className="text-danger">*</span></label>
                <Form.Control type="text" placeholder="Enter Your Name" />
              </Form.Group>
              <Form.Group className="mb-4">
                <label htmlFor="">Mobile Number <span className="text-danger">*</span></label>
                <Form.Control type="text" placeholder="Enter Mobile Number" />
              </Form.Group>

            </div>
            <Link className="btn btn-primary btn-block w-100 mt-auto" to="/otp">
              Send OTP
            </Link>

          </div>
        </div>
      </div>
    </div>
  );
};
