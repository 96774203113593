import React from "react";
import Header from "../../layouts/Header";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar
} from "@fortawesome/free-solid-svg-icons";
export default function RestaurantList() {
  return (
    <div className="page-section">
      <Header />
      <div className="common-container">
        <div className="container">
          <div className="restuarant-list">
            <h4 className="mb-4">Top Restaurant</h4>
            <div className="row">
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <Link to="/restaurant-action" >
                    <div className="restaurant-img">
                      <img src={"./assets/images/seafood.jpg"} alt="" />
                      <div className="rating  bg-success text-white h6 d-flex align-items-center font-weight-normal">
                        <span className=" mr-2 rating-box d-inline-block">
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span className="font-weight-normal">
                          4.0{" "}
                        </span>
                      </div>
                      <span className="discount-tag h6 bg-primary text-white">
                        20% Off
                      </span>
                      <div className="overlay"></div>
                    </div>
                  </Link>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi mb-0">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                    <div className="rating  bg-success text-white h6 d-flex align-items-center font-weight-normal">
                      <span className=" mr-2 rating-box d-inline-block">
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                      <span className="font-weight-normal">
                        4.0{" "}
                      </span>
                    </div>
                    <span className="discount-tag h6 bg-primary text-white">
                      20% Off
                    </span>
                    <div className="overlay"></div>
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/salad.jpg"} alt="" />
                    <div className="rating  bg-success text-white h6 d-flex align-items-center font-weight-normal">
                      <span className=" mr-2 rating-box d-inline-block">
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                      <span className="font-weight-normal">
                        4.0{" "}
                      </span>
                    </div>
                    <span className="discount-tag h6 bg-primary text-white">
                      20% Off
                    </span>
                    <div className="overlay"></div>
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                    <div className="rating  bg-success text-white h6 d-flex align-items-center font-weight-normal">
                      <span className=" mr-2 rating-box d-inline-block">
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                      <span className="font-weight-normal">
                        4.0{" "}
                      </span>
                    </div>
                    <span className="discount-tag h6 bg-primary text-white">
                      20% Off
                    </span>
                    <div className="overlay"></div>
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="restuarant-list">
            <h4 className="mb-4"> Restaurants By Cuisine</h4>
            <div className="row">
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                    <div className="rating  bg-success text-white h6 d-flex align-items-center font-weight-normal">
                      <span className=" mr-2 rating-box d-inline-block">
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                      <span className="font-weight-normal">
                        4.0{" "}
                      </span>
                    </div>
                    <span className="discount-tag h6 bg-primary text-white">
                      20% Off
                    </span>
                    <div className="overlay"></div>
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi mb-0">Thai</p>

                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Muglai</p>

                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/salad.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Italian</p>

                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Coastal</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="restuarant-list">
            <h4 className="mb-4">1367 Restaurants to explore </h4>
            <div className="row">
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi mb-0">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/salad.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-4">
                <div className="restaurant-box">
                  <div className="restaurant-img">
                    <img src={"./assets/images/seafood.jpg"} alt="" />
                  </div>
                  <div className="restaurant-meta">
                    <p className="text-capitalize font-weight-semi">Salad Corner</p>
                    <p className="font-small text-muted">Thai, Muglai</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
