import React from "react";
import Header from "../../layouts/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import {
  faLocationDot,
  faArrowRight,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
export default function PreviousOrders() {
  const navigate = useNavigate();
  return (
    <div className="page-section user-orders-main">
      <Header
        pageTitle="My Orders"
        isBackVisible={true}
      />
      <div className="common-container">
        <div className="container">
          <div className="mb-4">
            <h4 className="mb-3">Current Orders</h4>
            <div className="orders-list current-order-list">
              <div className="order shadow-lg bg-white rounded-lg">
                <h4 className="mb-2">Order Id : 02561</h4>
                <div className="order-info">
                  <div className="left mb-1">
                    <span className="text-muted  h6 font-weight-normal">
                      15 August, 2022, 04:33PM
                    </span>
                    <div className="d-flex align-items-center mb-1">
                      <h5 className="resturant-name">Chilli King</h5>
                      <p className="location mb-0 ms-3">
                        <span className="me-2">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        Canuaght Place
                      </p>
                    </div>
                    <p className="mb-1">
                      <span className="font-weight-semi">
                        <span className="me-2">
                          <FontAwesomeIcon icon={faUserTie} />
                        </span>
                        Waiter Assigned:
                      </span>
                      <span className="ms-2">Satpal Singh</span>
                    </p>
                  </div>
                  <div className="order-items mb-1">
                    <div className="order-item-list">
                      <span>
                        Butter Chiicken, Briyani, Rice,
                        <span
                          className="text-underline d-block"
                          onClick={() => {
                            navigate("/view-order-details");
                          }}
                        >
                          View Details
                          <span className="ms-2">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </span>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="order-status mt-3">
                    <h6>Order Status</h6>
                    <ul class="timeline">
                      <li class="active-tl">Ordered Received</li>
                      <li>Preparing</li>
                      <li>Ready to Serve</li>
                      <li>Served</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <h4 className="mb-3">Previous Orders</h4>
            <div className="orders-list">
              <div className="order shadow-lg bg-white rounded-lg">
                <h4 className="d-flex align-items-center justify-content-between">
                  Order Id : 02561
                  <span className="text-success h5 font-weight-normal">
                    Served
                  </span>
                </h4>

                <div className="order-info">
                  <div className="left">
                    <span className="text-muted  h6 font-weight-normal">
                      15 August, 2022, 04:33PM
                    </span>
                    <div className="d-flex align-items-center">
                      <h5 className="resturant-name">Chilli King </h5>
                      <p className="location mb-0 ms-3">
                        <span className="me-2">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        Canuaght Place
                      </p>
                    </div>
                  </div>
                  <div className="order-items">
                    <div className="order-item-list">
                      <span>
                        Butter Chiicken, Briyani, Rice,{" "}
                        <span className="text-underline d-block" onClick={() => {
                          navigate("/view-order-details");
                        }}>
                          View Details
                          <span className="ms-2">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                    <span>
                      <span className="font-weight-bold">₹80</span>
                      <span>{"(Paid Via Paytm)"}</span>
                    </span>
                    <button className="btn btn-primary py-2">Rate</button>
                  </div>
                </div>
              </div>
              <div className="order shadow-lg bg-white rounded-lg">
                <h4 className="d-flex align-items-center justify-content-between">
                  Order Id : 02561
                  <span className="text-success h5 font-weight-normal">
                    Served
                  </span>
                </h4>

                <div className="order-info">
                  <div className="left">
                    <span className="text-muted  h6 font-weight-normal">
                      15 August, 2022, 04:33PM
                    </span>
                    <div className="d-flex align-items-center">
                      <h5 className="resturant-name">Chilli King </h5>
                      <p className="location mb-0 ms-3">
                        <span className="me-2">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        Canuaght Place
                      </p>
                    </div>
                  </div>
                  <div className="order-items">
                    <div className="order-item-list">
                      <span>
                        Butter Chiicken, Briyani, Rice,{" "}
                        <span className="text-underline d-block" onClick={() => {
                          navigate("/view-order-details");
                        }}>
                          View Details
                          <span className="ms-2">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                    <span>
                      <span className="font-weight-bold">₹80</span>
                      <span>{"(Paid Via Paytm)"}</span>
                    </span>
                    <button className="btn btn-primary py-2">Rate</button>
                  </div>
                </div>
              </div>
              <div className="order shadow-lg bg-white rounded-lg">
                <h4 className="d-flex align-items-center justify-content-between">
                  Order Id : 02561
                  <span className="text-success h5 font-weight-normal">
                    Served
                  </span>
                </h4>

                <div className="order-info">
                  <div className="left">
                    <span className="text-muted  h6 font-weight-normal">
                      15 August, 2022, 04:33PM
                    </span>
                    <div className="d-flex align-items-center">
                      <h5 className="resturant-name">Chilli King </h5>
                      <p className="location mb-0 ms-3">
                        <span className="me-2">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        Canuaght Place
                      </p>
                    </div>
                  </div>
                  <div className="order-items">
                    <div className="order-item-list">
                      <span>
                        Butter Chiicken, Briyani, Rice,{" "}
                        <span className="text-underline d-block" onClick={() => {
                          navigate("/view-order-details");
                        }}>
                          View Details
                          <span className="ms-2">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                    <span>
                      <span className="font-weight-bold">₹80</span>
                      <span>{"(Paid Via Paytm)"}</span>
                    </span>
                    <button className="btn btn-primary py-2">Rate</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
