import React, { useState } from "react";
import Header from "../../layouts/Header";

import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Cart() {
  const [showAddOnModal, setShowAddOnModal] = useState(false);

  const handleCloseAddOnModal = () => setShowAddOnModal(false);
  const handleShowAddOnModal = () => setShowAddOnModal(true);
  const navigate = useNavigate();
  return (
    <div className="page-section cart-main">
      <Header
        pageTitle="Cart"
        isBackVisible={true}
      />
      <div className="common-container">
        <div className="container">
          <h4 className="mb-3 pt-3">Cart (8 Items Added)</h4>
          <div className="cart-inner">
            <div className="cart-items-list">
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-success food-type text-success d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Veg Kathi Roll</h5>
                    </div>
                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-danger food-type text-danger d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Chicken Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-success food-type text-success d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Veg Kathi Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-danger food-type text-danger d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Chicken Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-success food-type text-success d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Veg Kathi Roll</h5>
                    </div>
                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-danger food-type text-danger d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Chicken Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-success food-type text-success d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Veg Kathi Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
              <div className="cart-item">
                <div className="d-flex top">
                  <div className="left d-flex align-items-start flex-column">
                    <div className="d-flex">
                      <span className="border border-danger food-type text-danger d-inline-block font-small flex-grow-1">
                        <FontAwesomeIcon icon={faCircle} />
                      </span>
                      <h5 className="mb-0">Chicken Roll</h5>
                    </div>

                    <div className="bottom mt-1">
                      <span className="add-ons me-4 font-small" onClick={handleShowAddOnModal}>
                        Add On & Instructions
                        <span className="mst-2">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="quantity text-end">
                    <span className="price font-weight-bold ms-auto me-2 ">₹80</span>
                    <span className="btn btn-primary shadow-sm text-primary mt-1 py-0 font-small  d-flex  align-items-center p-0">
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        -
                      </button>
                      <span className="text-muted font-weight-bold">
                        1
                      </span>
                      <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                        +
                      </button>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div className="add-more mt-4">
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate("/food-menu");
                }}
              >
                Add More Items{" "}
              </button>
            </div>
            {/* <div className="text-center">
              <img src={"./assets/images/breakfast.png"} alt="food" />
              <h3>Your Cart Looks Delicious!</h3>
            </div> */}



            {/* <div className="grand-total border  shadow ">
              <div className="border-bottom">
                <p className="d-flex align-items-center justify-content-between">
                  <span>Sub Total</span>
                  <span className="price font-weight-medium">
                    ₹800
                  </span>
                </p>
                <p className="d-flex align-items-center justify-content-between">
                  <span>Taxes</span>
                  <span className="price font-weight-medium">
                    ₹120
                  </span>
                </p>
                <p className="d-flex text-success font-weight-semi align-items-center justify-content-between">
                  <span>Discount</span>
                  <span className="price font-weight-medium">
                    ₹120
                  </span>
                </p>
              </div>
              <p className="d-flex align-items-center h5 justify-content-between pt-2">
                <span>Total</span>
                <span className="price font-weight-bold">
                  ₹800
                </span></p>
            </div> */}
          </div>
        </div>

        <div className="action fixed-bottom py-2 justify-content-end bg-white d-flex align-items-center">

          <button className="btn py-2 btn-primary" onClick={() => {
            navigate("/welcome-screen");
          }}>Place Order
            {/* <FontAwesomeIcon icon={}/> */}
          </button>
        </div>
      </div>

      {/* Addon Modals  */}

      <Modal
        show={showAddOnModal}
        className="addon-modal"
        centered
        onHide={handleCloseAddOnModal}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <h5>Chicken Role</h5>
          <h6 className="font-weight-normal">Customise as per your choice</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="addon-list">
            <div className="add-on-items">
              <div className="add-on-box">
                <h5 className="add-category-title">Choice of Bread</h5>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Whole Wheat</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Whole Wheat</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Maida</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                </div>
              </div>
              <div className="add-on-box">
                <h5 className="add-category-title">Choice of Meat</h5>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Chicken</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Mutton</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Keema</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                </div>
              </div>
              <div className="add-on-box">
                <h5 className="add-category-title">Choice of Eggs</h5>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Single Egg</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice3" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Double Egg</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice3" type="radio" />
                  </div>
                </div>
              </div>
            </div>
            <div className="add-instructions mb-4">
              <h5 className="mb-3">Add Instructions</h5>
              <textarea
                className="form-control"
                rows={3}
                placeholder="Add instructions"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="py-2 font-small"
            onClick={() => {
              handleCloseAddOnModal();
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Addon Modals  */}
    </div>
  );
}
