export const routes = {
  public: {
    signin: "/sign-in",
    signup: "/sign-up",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    welcomeScreen: "welcome-screen",
    OTP: "otp",
    RestaurantList: "restaurant-list",
    FoodMenu: "food-menu",
    RestaurantAction: "restaurant-info",
    TableReservation: "table-reservation",
    Cart: "view-cart",
    FinalBillPreview: "final-bill-preview",
    Profile: "profile",
    PreviousOrders: "previous-orders",
    Payment: "payment",
    ViewOrderDetails: "view-order-details",
    OrderPlaced: "order-placed",
    TimingSelection: "timing-selection",
    TableReservationSuccess: "table-reservation-success",
    PackagesList: "packages-list",
    PackagesDetails: "package-details",
    PackagesCustomize: "packages-customize",
    BookedPackageDetails: "booked-package-details",
    // PackagesList: "packages-list",
    // PackagesList: "packages-list",
    // PackagesList: "packages-list",


  },
  private: {
    dashboard: "/dashboard",
    categoryList: "/category-list",
    viewCategoryList: "/view-category-list/:id",
    addMenuItem: "/add-menu-item",
    editMenuItem: "/edit-menu-item/:id",
    addFoodCategory: "/add-food-category",
    editFoodCategory: "/edit-food-category/:id",
    viewMenuItemList: "/menu-item-list",
    viewTableList: "/table-list",
    addTable: "/add-table",
    tableManagement: "/table-management",
  },
};
