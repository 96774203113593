import React from "react";
import { Form, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
export const OTP = () => {
  return (
    <div className="common-login">
      <div className="common-container">
        <div className="inner d-flex flex-column">
          <div className="top">
            <img src={"/assets/images/login/slider-1.svg"} alt="" />
            <h3 className="text-primary title">OTP</h3>

          </div>
          <div className="bottom  flex-grow-1 d-flex flex-column">

            <div className="form-group">
              <label>Please Enter OTP</label>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <Form.Control size="lg" type="text" maxLength={1} className="text-center" />
                </div>
              </div>
            </div>
            <p>
              OTP not received? <span className="text-primary font-weight-semi">RESEND</span>
            </p>
            <div className="form-group mt-auto">
              <Link
                className="btn btn-primary btn-block w-100"
                to="/order-placed"
              >
                Login
              </Link>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};
