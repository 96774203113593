import { combineReducers } from "redux";
import { signin_user_reducer } from "../pages/Auth/redux";


const rootReducer = combineReducers({
  //auth
  signin_user_reducer,

});

export default rootReducer;
