import React from "react";
import Header from "../../layouts/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faStar,
  faMapLocation
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function RestaurantAction() {
  const navigate = useNavigate();
  return (
    <div className="page-section restaurant-page">
      <Header
        pageTitle="Restaurant Info"
        isBackVisible={true}
      />
      <div className="common-container">
        <div className="restaurant-cover-img">
          <img src={"./assets/images/moctail.jpg"} className="cover-image" alt="cover-img" />

          <div className="overlay"></div>

          <div className="rating bg-success text-white h5 d-flex align-items-center font-weight-normal">
            <span className=" mr-2 rating-box d-inline-block">
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span className="font-weight-normal">
              4.0{" "}
            </span>
          </div>
          <span className="discount-tag h5 bg-primary text-white">
            20% Off
          </span>
        </div>

        <div className="restaurant-details-info ">
          <div className="container">
            <div className="restaurant-overview rounded-lg p-3 mb-4 shadow-lg">
              <h4 className="mb-2">Tamasha</h4>
              <p className="mb-2">
                <FontAwesomeIcon className="text-muted me-3" icon={faMapLocation} />
                Pandara Road, New Delhi
              </p>
              <ul className="d-flex align-items-center mb-2 font-smallest">
                <li>
                  <strong>₹2,000 for 2 </strong>
                </li>
                <li><span className="mx-2">•</span></li>
                <li>Continental, </li>
                <li>Asian, </li>
                <li>Italian, </li>
                <li>North Indian</li>
              </ul>
              <p className="font-small">
                <span className="text-success font-weight-semi">Open Now</span>{" "}
                <span className="mx-2">•</span>{" "}
                <span className="">closed by 11.00PM</span>
              </p>
            </div>
            <Link to="/packages-list" className="text-dark">
              <div className="reserve-table mb-4 bg-white rounded-lg p-3 mb-2 shadow-lg d-flex align-items-center p-3">
                <img src={"./assets/images/confetti.png"} alt="" />
                <h4 className="mb-0">Book for an Event</h4>
              </div>
            </Link>
            <Link to="/table-reservation" className="text-dark">
              <div className="reserve-table mb-4 bg-white rounded-lg p-3 mb-2 shadow-lg d-flex align-items-center p-3">
                <img src={"./assets/images/dinner-table.png"} alt="" />
                <h4 className="mb-0">Reserve A Table</h4>
              </div>
            </Link>
            <Link to="/food-menu" className="text-dark">
              <div className="view-menu p-3 d-flex align-items-center shadow-lg rounded-lg bg-white mb-4">
                <img src={"./assets/images/cutlery.png"} alt="" />
                <h4 className="mb-0">Checkout Menu</h4>

              </div>
            </Link>
            <div className="about-restaurant mb-4 rounded-lg p-3 align-items-center shadow-lg bg-white">
              <div className="mb-3">
                <h4 className="mb-3">About</h4>
                <p className="font-medium">Tamasha is one of the most popular places to hang out in Heart of Delhi-CP. This Restaurant has Distinct Seating Areas including a Courtyard, Ground Floor, First Floor, Backyard and an Amazing Rooftop over spacious ambience. Tamasha offers multi-cuisine dishes from North Indian, Italian, Continental and Asian cuisines and an array of Finger Food. Tamasha is an ideal place for a Night Out Friends or good times with Family Members. The eye-catching bar is built to resemble Optimus Prime, a fictional character from the Transformers Franchise. This is a super awesome place which is definitely worth going for one and all!</p>

              </div>
              <h4 className="mb-3">Connect Us</h4>
              <ul className="d-flex align-items-center">
                <li><a href="#" target="_blank"><img src={"./assets/images/facebook-logo.png"} alt="facebook" /></a></li>
                <li className="ms-2"><a href="#" target="_blank"><img src={"./assets/images/instagram-logo.png"} alt="instagram" /></a></li>
                <li className="ms-2"><a href="#" target="_blank"><img src={"./assets/images/youtube-logo.png"} alt="youtube" /></a></li>
                <li className="ms-2"><a href="#" target="_blank"><img src={"./assets/images/twitter-logo.png"} alt="twitter" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
