import React from "react";
import Header from "../../layouts/Header";

import { FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faArrowRight,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();

  return (
    <div className="page-section profile-main">
      <Header />
      <div className="common-container">
        <div className="container">
          <h4 className="mb-3">My Profile</h4>
          <div className="border shadow p-3 bg-white rounded">
            <div className="form-group mb-4">
              <label class="profile-picture" htmlFor="userImg">
                <input type="file" id="userImg" className="d-none" />
                <span className="img-box">
                  <img src="/assets/images/avatar.png" alt="user" />
                </span>
                <span className="edit-icon">
                  <FontAwesomeIcon icon={faPencil} className="text-success rounded-circle" />
                </span>
              </label>
            </div>
            <div className="form-group mb-4">
              <FloatingLabel
                controlId="floatingInputName"
                label="Name"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="Enter Your Name" defaultValue="Mukesh Singh " />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInputMobile" label="Mobile Number" className="mb-3">
                <Form.Control type="tel" placeholder="Mobile Number" defaultValue="88029968014" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInputEmail" label="Mobile Number">
                <Form.Control type="tel" placeholder="Enter Email id" defaultValue="mukesh@test.com" />
              </FloatingLabel>
            </div>
          </div>


          <div className="user-orders-main mt-4">
            
              <div className="mb-4">
                <h4 className="mb-3">Your Last Orders</h4>
                <div className="orders-list p-3 border bg-white shadow rounded">
                  <div className="order">
                    <h4 className="d-flex align-items-center justify-content-between">
                      Order Id : 02561
                      <span className="text-success h5 font-weight-normal">
                        Served
                      </span>
                    </h4>

                    <div className="order-info">
                      <div className="left">
                        <span className="text-muted  h6 font-weight-normal">
                          15 August, 2022, 04:33PM
                        </span>
                        <div className="d-flex align-items-center">
                          <h5 className="resturant-name">Chilli King </h5>
                          <p className="location mb-0 ms-3">
                            <span className="me-2">
                              <FontAwesomeIcon icon={faLocationDot} />
                            </span>
                            Canuaght Place
                          </p>
                        </div>
                      </div>
                      <div className="order-items">
                        <div className="order-item-list">
                          <span>
                            Butter Chiicken, Briyani, Rice,{" "}
                            <span className="text-underline d-block" onClick={() => {
                              navigate("/view-order-details");
                            }}>
                              View Details
                              <span className="ms-2">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                        <span>
                          <span className="font-weight-bold">₹80</span>
                          <span>{"(Paid Via Paytm)"}</span>
                        </span>
                        <button className="btn btn-primary py-2">Rate</button>
                      </div>
                    </div>
                  </div>
                  <div className="order">
                    <h4 className="d-flex align-items-center justify-content-between">
                      Order Id : 02561
                      <span className="text-success h5 font-weight-normal">
                        Served
                      </span>
                    </h4>

                    <div className="order-info">
                      <div className="left">
                        <span className="text-muted  h6 font-weight-normal">
                          15 August, 2022, 04:33PM
                        </span>
                        <div className="d-flex align-items-center">
                          <h5 className="resturant-name">Chilli King </h5>
                          <p className="location mb-0 ms-3">
                            <span className="me-2">
                              <FontAwesomeIcon icon={faLocationDot} />
                            </span>
                            Canuaght Place
                          </p>
                        </div>
                      </div>
                      <div className="order-items">
                        <div className="order-item-list">
                          <span>
                            Butter Chiicken, Briyani, Rice,{" "}
                            <span className="text-underline d-block" onClick={() => {
                              navigate("/view-order-details");
                            }}>
                              View Details
                              <span className="ms-2">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                        <span>
                          <span className="font-weight-bold">₹80</span>
                          <span>{"(Paid Via Paytm)"}</span>
                        </span>
                        <button className="btn btn-primary py-2">Rate</button>
                      </div>
                    </div>
                  </div>
                  <div className="order">
                    <h4 className="d-flex align-items-center justify-content-between">
                      Order Id : 02561
                      <span className="text-success h5 font-weight-normal">
                        Served
                      </span>
                    </h4>

                    <div className="order-info">
                      <div className="left">
                        <span className="text-muted  h6 font-weight-normal">
                          15 August, 2022, 04:33PM
                        </span>
                        <div className="d-flex align-items-center">
                          <h5 className="resturant-name">Chilli King </h5>
                          <p className="location mb-0 ms-3">
                            <span className="me-2">
                              <FontAwesomeIcon icon={faLocationDot} />
                            </span>
                            Canuaght Place
                          </p>
                        </div>
                      </div>
                      <div className="order-items">
                        <div className="order-item-list">
                          <span>
                            Butter Chiicken, Briyani, Rice,{" "}
                            <span className="text-underline d-block" onClick={() => {
                              navigate("/view-order-details");
                            }}>
                              View Details
                              <span className="ms-2">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top mt-2 py-2">
                        <span>
                          <span className="font-weight-bold">₹80</span>
                          <span>{"(Paid Via Paytm)"}</span>
                        </span>
                        <button className="btn btn-primary py-2">Rate</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
