import React from "react";
import Header from "../../layouts/Header";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function PackagesList() {
const navigate=useNavigate();
const navigatePackageHandler= ()=>{
  navigate("/package-details")
}
  return (
    <div className="page-section packages-list-wrapper">
      <Header
        pageTitle="Packages"
        isBackVisible={true} />
      <div className="common-container">
        <div className="container">
          <div className="packages-list">
            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/birthday-package.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Birthday Parties</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/friends-get-together.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Friends Get Together</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/family-get-togethers.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Family Get Together</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/corporate-package.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Corporate Parties</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/bachelors-parties-package.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Bachelors Parties</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/bachelorette-parties-packages.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Bachelorette Parties</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
              <div className="img-box  rounded overflow-hidden">
                <img src={"./assets/images/engagment-package.jpg"} alt="package img" />
              </div>
              <div className="content mt-2 text-center">
                <h3 className="text-primary mb-2">Engagement Parties</h3>
                <button className="btn btn-primary py-2" onClick={navigatePackageHandler}>View Details </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
