import React, { useState } from "react";
import Header from "../../layouts/Header";

import { Accordion, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faMapLocation, faCircle, faSearch, faStar, faBowlFood, faBowlRice, faCircleCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function FoodMenu() {
  const location = useLocation();
 
  const [showAddOnModal, setShowAddOnModal] = useState(false);
  const [showTableBookingModal, setShowTableBookingModal] = useState(location?.state === "table-booking");

  const handleCloseAddOnModal = () => setShowAddOnModal(false);
  const handleShowAddOnModal = () => setShowAddOnModal(true);

  const handleCloseTableBooking = () => setShowTableBookingModal(false);
  const handleShowTableBooking = () => setShowTableBookingModal(true);

  const [showCartAction, setShowCartAction] = useState(false);

  const handleShowCartAction = () => setShowCartAction(true);
  return (
    <div className="page-section food-menu-main">
      <Header
        pageTitle="Menu"
        isBackVisible={false}
      />
      <div className="common-container">
        <div className="container">
          <div className="resturant-info bg-white mb-4 p-3">
            <h3 className="d-flex align-items-center">
              <span className="me-2 text-primary">Tamasha</span>
              <div className="rating ms-auto bg-success h5 text-white rounded py-1 px-2 d-flex align-items-center font-weight-normal">
                <span className="text-white font-small rating-box d-inline-block">
                  <FontAwesomeIcon icon={faStar} />
                </span>
                <span className="font-weight-normal ms-1">4.0 </span>
              </div>
            </h3>
            <p className="mb-2">Beverages, Fast Food, Burger, Coffee</p>
            <p className="">
              <FontAwesomeIcon className="me-3" icon={faMapLocation} />
              Pandara Road, New Delhi
            </p>
            <Link to="/restaurant-info" className="text-dark">
              View More <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </Link>
          </div>
          <div className="pb-3">
            <div className="d-flex form-group rounded-lg align-items-center border rounded bg-light-2 px-3">
              <span>
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="text"
                className="form-control ps-2 border-0"
                placeholder="Search from Menu"
              />
            </div>
          </div>
          <div className="filters bg-white d-flex align-items-center py-3 px-3 rounded-lg shadow-lg mb-3">
            <span className="font-weight-bold me-4">Veg Only</span>
            <label className="form-switch switch-green" htmlFor="VegOnlyFilter">
              <input type="checkbox" className="form-check-input" id="VegOnlyFilter" />
            </label>
          </div>
          <div className="food-list">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Recommended (20)</h4>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                  <div className="food-items-list">   
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/burgers.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Burger
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/curries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Chicken Curry
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/grills.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Grilled Chicken 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/coffee.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Milk Coffee 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fruit-salad.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Fruit Salad 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              French Fries 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h4>Sides (20)</h4>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                <div className="food-items-list">   
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/burgers.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Burger
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/curries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Chicken Curry
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/grills.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Grilled Chicken 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/coffee.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Milk Coffee 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fruit-salad.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Fruit Salad 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              French Fries 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h4>Sandwiches (20)</h4>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                <div className="food-items-list">   
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/burgers.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Burger
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/curries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Chicken Curry
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/grills.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Grilled Chicken 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/coffee.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Milk Coffee 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fruit-salad.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Fruit Salad 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              French Fries 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h4>Salads (20)</h4>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                <div className="food-items-list">   
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/burgers.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Burger
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/curries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Chicken Curry
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/grills.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-danger me-2 food-type text-danger text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Grilled Chicken 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/coffee.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Milk Coffee 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fruit-salad.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              Fruit Salad 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-muted font-small customise-text mb-0">Customisable</p>
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowAddOnModal}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                    <div className="food-item">
                      <div className="d-flex align-items-start">
                        <div className="food-item-img">
                          <img
                            src={"assets/images/fries.jpg"}
                            alt=""
                            className="food-img"
                          />
                        </div>
                        <div className="food-info">
                          <h4 className="food-item-name d-flex align-items-start mb-1 font-weight-medium">
                            <span className="border border-success me-2 food-type text-success text-center d-inline-block font-small mt-1" >
                              <FontAwesomeIcon icon={faCircle} />
                            </span>
                            <div className="item-name">
                              French Fries 
                            </div>
                          </h4>
                          <div className="d-flex align-items-center font-medium mb-2 mt-2 font-weight-semi">
                            <span className="price text-deleted text-danger me-3">
                              ₹100
                            </span>
                            <span className="discounted-price">₹80</span>
                            <p className="mb-0 ms-2 font-weight-normal">
                              <span className="me-1">
                                <FontAwesomeIcon icon={faBowlRice} />
                              </span>
                              <span className="text-muted"> 45 Mins</span>
                            </p>
                          </div>

                          <p className="desc font-small text-muted">
                            Lorem ipsum dolor, sit amet consectetur <span className="text-underline">View More</span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="action ms-auto d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-primary shadow-sm text-primary py-2 font-small"
                            onClick={handleShowCartAction}
                          >
                            + Add
                          </button>
                          {/* <span className="btn btn-primary shadow-sm mb-3 text-primary py-0 font-small  d-flex  align-items-center p-0">
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                -
                              </button>
                              <span className="text-muted font-weight-bold">
                                1
                              </span>
                              <button className="border-0 bg-transparent text-white py-1 px-3 h3 mb-0">
                                +
                              </button>
                            </span> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>


      <Modal show={showTableBookingModal} className="addon-modal" centered onHide={handleCloseTableBooking}
      >

        <Modal.Body className="text-center">
          <h1 className="font-largest text-primary mb-4">
            <FontAwesomeIcon icon={faCircleInfo} />
          </h1>
          <h4 className="mb-3">You need to pre order food for table booking confirmation
          </h4>
          <p className="text-muted">Note: Minimum order value ₹500 </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">

          <Button variant="primary" className="btn btn-primary" onClick={() => {
            handleCloseTableBooking();
          }}>
            Got It!
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddOnModal} className="addon-modal" centered onHide={handleCloseAddOnModal}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <h3 className="text-primary">Chicken Role</h3>
          <h6 className="font-weight-normal">Customise as per your choice</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="addon-list">
            <div className="add-on-items">
              <div className="add-on-box">
                <h4 className="add-category-title text-primary">Choice of Bread</h4>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Whole Wheat</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Whole Wheat</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-success food-type text-success d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Maida</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice1" type="radio" />
                  </div>
                </div>
              </div>
              <div className="add-on-box">
                <h4 className="add-category-title text-primary">Choice of Meat</h4>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Chicken</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Mutton</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Keema</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice2" type="radio" />
                  </div>
                </div>
              </div>
              <div className="add-on-box">
                <h4 className="add-category-title text-primary">Choice of Eggs</h4>
                <div className="add-on-choices">
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Single Egg</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice3" type="radio" />
                  </div>
                  <div className="add-on-choice">
                    <span className="border border-danger food-type text-danger d-inline-block font-small">
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <p className="choice-title">Double Egg</p>
                    <span className="add-on-price">₹80</span>
                    <Form.Check inline name="Choice3" type="radio" />
                  </div>
                </div>
              </div>

            </div>
            <div className="add-instructions mb-4">
              <h4 className="mb-3 text-primary">Add Instructions</h4>
              <textarea
                className="form-control"
                rows={3}
                placeholder="Add instructions"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="col-12 px-0 ">
            <p className="font-weight-bold d-block mb-0 text-primary">Add Ons</p>
            <p className="text-muted mb-0 font-small">Whole Wheat, Chicken, Eggs, </p>
          </div>
          <span className="font-weight-bold mr-3">
            <span className="d-block text-primary">Total</span>
            <span className="h3 mb-0  text-primary">₹800</span>
          </span>
          <Button
            variant="primary"
            className="py-2"
            onClick={() => {
              handleShowCartAction();
              handleCloseAddOnModal();
            }}
          >
            Add to Cart
          </Button>
        </Modal.Footer>
      </Modal>

      {showCartAction ? (
        <>
          <div className="view-cart-action bg-white py-2 fixed-bottom">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-5">
                  <p className="mb-0 text-primary">1 Item Added</p>
                  <h3 className="mb-0">₹800</h3>
                </div>
                <div className="col-7 text-end">
                  <Link
                    to="/view-cart"
                    className="btn btn-primary py-2"
                  >
                    View Cart
                    <span className="ms-2">
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
