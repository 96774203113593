import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCheckCircle,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useNavigate } from "react-router-dom";
const images = [
  // 'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image1.jpg',
  'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image2.jpg',
  'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image3.jpg',
  'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image4.jpg',
  'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image5.jpg',
  'https://www.jqueryscript.net/demo/Responsive-Touch-enabled-jQuery-Image-Lightbox-Plugin/images/image6.jpg',
];


export default function PackagesDetails() {
  const navigate = useNavigate();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [showChoicesModal, setShowChoicesModal] = useState(false);
  const handleChoicesModalClose = () => setShowChoicesModal(false);
  const handleChoicesModalShow = () => setShowChoicesModal(true);

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };
  const navigatePackageCustomize=()=>{
  navigate("/packages-customize");
  }
  return (
    <div className="page-section packages-information-wrapper">
      <Header
        pageTitle="Packages"
        isBackVisible={true} />
      <div className="common-container">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-3 mb-4 display-image">
            <div className="img-box rounded overflow-hidden">
              <img src={"./assets/images/birthday-package.jpg"} alt="package img" />
              <button className="bg-white shadow border-0 p-2 rounded-circle btn-view-all-images">
                <img src={"./assets/images/picture.png"} alt="view pictures" onClick={() => setIsGalleryOpen(true)} />
              </button>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
            <div className="d-flex align-items-center">
              <span className="font-weight-bold text-nowrap">Select Package:</span>
              <div className="flex-grow-1 ms-3">
                <select name="" id="" className="form-select">
                  <option value="veg">Veg Package</option>
                  <option value="veg">Veg/Non-Veg Package</option>
                  <option value="veg">Veg/Non-Veg/Liqour Package</option>
                </select>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
            <h3 className="mb-3">Veg Package</h3>
            <div className="row">
              <div className="col-5">
                <p className="font-weight-semi text-dark">Minimum People</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">8 People</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Cake</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">1 Kg (of your Choice) <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Starters</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Main Course </p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Party Duration</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">3 hours</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Price</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">Rs. 800/- per person</p>
              </div>
              <div className="col-12">
                <button className="btn btn-primary py-2 mt-3 w-100" onClick={navigatePackageCustomize}>Customize</button>
              </div>
            </div>

          </div>

          <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
            <h3 className="mb-3">Veg/Non-Veg Package</h3>
            <div className="row">
              <div className="col-5">
                <p className="font-weight-semi text-dark">Minimum People</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">8 People</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Cake</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">1 Kg (of your Choice) <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Starters</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>

                <p className="font-small font-weight-medium d-block">3 Non Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Main Course </p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>

                <p className="font-small font-weight-medium d-block">3 Non Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Party Duration</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">3 hours</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Price</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">Rs. 1000/- per person</p>
              </div>
              <div className="col-12">
                <button className="btn btn-primary py-2 mt-3 w-100" onClick={navigatePackageCustomize}>Customize</button>
              </div>
            </div>

          </div>

          <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
            <h3 className="mb-3">Veg/Non-Veg/Liqour Package</h3>
            <div className="row">
              <div className="col-5">
                <p className="font-weight-semi text-dark">Minimum People</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">8 People</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Cake</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">1 Kg (of your Choice) <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Starters</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>

                <p className="font-small font-weight-medium d-block">3 Non Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Main Course </p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">3 Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
                <p className="font-small font-weight-medium d-block">3 Non Veg Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Alcohol </p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium d-block">3  Items (of your Choice)- Unlimited <p className="text-primary text-underline" onClick={handleChoicesModalShow}>see choices</p></p>

              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Party Duration</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">3 hours</p>
              </div>
              <div className="col-5">
                <p className="font-weight-semi text-dark">Price</p>
              </div>
              <div className="col-7">
                <p className="font-small font-weight-medium">Rs. 1000/- per person</p>
              </div>
              <div className="col-12">
                <button className="btn btn-primary py-2 mt-3 w-100" onClick={navigatePackageCustomize}>Customize</button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      {isGalleryOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsGalleryOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      <Modal show={showChoicesModal} centered onHide={handleChoicesModalClose}>
        <Modal.Header closeButton >
          <Modal.Title>Choices Available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Chicken Tikka</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Paneer Roll</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Sprill Roll</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Spicy Veg Roll</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Spicy Veg Chilla</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Malai Soya Chaap</li>
            <li className="d-flex align-items-start mb-2 font-weight-semi">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-primary me-3" />
              Achari Soya Chaap</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="py-2" onClick={handleChoicesModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
}
