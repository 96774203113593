import React from "react";
import Header from "../../layouts/Header";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function TimingSelection() {
  const navigate = useNavigate();

  return (
    <div className="page-section reservation-timing-page pb-5">
      <Header />
      <div className="common-container">

        <div className="container">
          <h3 className="pt-3 mb-4">Select Available Time Slot</h3>
          <div className="text-center">
            <img src={"./assets/images/select-time-img.png"} alt="time" />
          </div>
          <ul className="available-timing-list d-flex align-items-center flex-wrap">
            <li>
              <label htmlFor="timing1">
                <input type="radio" id="timing1" name="timingSelection" />
                <button className="btn btn-success">01:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing2">
                <input type="radio" id="timing2" name="timingSelection" />
                <button className="btn btn-success">02:00 PM</button>
              </label>

            </li>
            <li>
              <label htmlFor="timing3">
                <input type="radio" id="timing3" name="timingSelection" />
                <button className="btn btn-success">03:00 PM</button>
              </label>

            </li>
            <li>
              <label htmlFor="timing4">
                <input type="radio" id="timing4" name="timingSelection" />
                <button className="btn btn-success">04:00 PM</button>
              </label>

            </li>
            <li>
              <label htmlFor="timing5">
                <input type="radio" id="timing5" name="timingSelection" />
                <button className="btn btn-success">05:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing6">
                <input type="radio" id="timing6" name="timingSelection" />
                <button className="btn btn-success">06:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing7">
                <input type="radio" id="timing7" name="timingSelection" />
                <button className="btn btn-success">07:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing8">
                <input type="radio" id="timing8" name="timingSelection" />
                <button className="btn btn-success">08:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing9">
                <input type="radio" id="timing9" name="timingSelection" />
                <button className="btn btn-success">09:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing10">
                <input type="radio" id="timing10" name="timingSelection" />
                <button className="btn btn-success">10:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing11">
                <input type="radio" id="timing11" name="timingSelection" />
                <button className="btn btn-success">11:00 PM</button>
              </label>
            </li>
            <li>
              <label htmlFor="timing12">
                <input type="radio" id="timing12" name="timingSelection" />
                <button className="btn btn-success">12:00 PM</button>
              </label>
            </li>
          </ul>
          <div className="action mt-4">
            <button className="btn btn-primary w-100 py-3" onClick={() => {
              navigate("/food-menu", { state: "table-booking" });
            }}>
              continue
            </button>
          </div>
        </div>
      </div>
    </div>

  );
}
