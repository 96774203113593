import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export const WelcomeScreen = () => {
  const navigate=useNavigate();
  return (
    <div className="common-login welcome-screen text-white bg-primary d-flex flex-column">
      <div className="company-logo">
        <img src={"./assets/images/welcome-logo.png"} alt="logo" />

      </div>
      <h1 className="text-white">FunDine</h1>
      <p>Enjoy the Fine Dine Experience .</p>
      <div className="bottom-content mt-auto">
        <img src={"./assets/images/welcome-bottom.png"} alt="logo" />
        <button className="btn btn-white w-100" onClick={()=>{navigate("/sign-in")}}>
          Get Started
        </button>
      </div>
    </div>
  );
};
