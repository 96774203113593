import React from "react";
import Header from "../../layouts/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function FinalBillPreview() {
  const navigate = useNavigate();
  return (
    <div className="page-section order-details-main">
      <Header
        pageTitle="Order Information"
        isBackVisible={true}
      />
      <div className="common-container">
        <div className="container">
          <h4 className="mb-3">Order Details</h4>
          <div className="ordered-items-list  shadow-lg rounded-lg bg-white mb-4">
            <div className="ordered-item d-flex font-weight-bold heading border-bottom mb-2 pb-2">
              <span className="item-name text-primary">Item</span>
              <span className="item-quantity text-primary">
                <span className="">&nbsp;</span>
                <span>Quantity</span>
              </span>
              <span className="item-price text-primary">Price</span>
              <span className="item-total-price text-primary font-weight-bold">
                Amount
              </span>
            </div>
            <div className="ordered-item d-flex">
              <span className="item-name">Chicken</span>
              <span className="item-quantity">
                <span className="me-4">X</span>
                <span>2</span>
              </span>
              <span className="item-price">₹80</span>
              <span className="item-total-price font-weight-bold">
                ₹80
              </span>
            </div>
            <div className="ordered-item d-flex">
              <span className="item-name">Chicken</span>
              <span className="item-quantity">
                <span className="me-4">X</span>
                <span>2</span>
              </span>
              <span className="item-price">₹80</span>
              <span className="item-total-price font-weight-bold">
                ₹80
              </span>
            </div>
            <div className="ordered-item d-flex">
              <span className="item-name">Prawn Curry</span>
              <span className="item-quantity">
                <span className="me-4">X</span>
                <span>2</span>
              </span>
              <span className="item-price">₹80</span>
              <span className="item-total-price font-weight-bold">
                ₹80
              </span>
            </div>
            <div className="ordered-item d-flex">
              <span className="item-name">Burger</span>
              <span className="item-quantity">
                <span className="me-4">X</span>
                <span>2</span>
              </span>
              <span className="item-price">₹80</span>
              <span className="item-total-price font-weight-bold">
                ₹80
              </span>
            </div>


            {/* <div className="border-top d-flex align-items-center py-2 mt-3 justify-content-between">
              <span className="mr-2">
                <FontAwesomeIcon icon={faClock} className="me-2" />
                <span>{counter} Sec left</span>
              </span>

              <button className="btn btn-primary"
                disabled={counter === 0}

                onClick={() => {
                  navigate("/view-cart");
                }}
              >Edit Order</button>

            </div> */}
          </div>

          <div className="apply-code shadow-lg bg-white mt-4 p-3 rounded-lg">
            <p className="d-flex align-items-center justify-content-between mb-0 font-small">
              <span className="font-weight-semi">Have any Promo Code ?</span>
              <Link to="/" className="btn btn-primary py-2">Apply</Link>
            </p>
          </div>

          <div className="apply-code bg-white shadow-lg mt-4 p-3 rounded-lg">
            <p className="d-flex align-items-center justify-content-between mb-0 font-small">
              <span>
                <span className="font-weight-semi">"WELCOME50" Applied</span>
                <span className="d-block">
                  <span className="text-success me-2 font-weight-bold">
                    <FontAwesomeIcon icon={faCheck} className="me-2" />
                    ₹120
                  </span>
                  Coupon Savings
                </span>
              </span>
              <button to="/" className="btn btn-primary py-2">Remove</button>
            </p>
          </div>


          <div className="grand-total  bg-white shadow-lg mt-4 p-3 rounded-lg ">
            <div className="border-bottom">
              <p className="d-flex align-items-center justify-content-between">
                <span>Sub Total</span>
                <span className="price font-weight-medium">
                  ₹800
                </span>
              </p>
              <p className="d-flex align-items-center justify-content-between">
                <span>Taxes</span>
                <span className="price font-weight-medium">
                  ₹120
                </span>
              </p>
              <p className="d-flex text-success font-weight-semi align-items-center justify-content-between">
                <span>Discount</span>
                <span className="price font-weight-medium">
                  ₹120
                </span>
              </p>
            </div>
            <p className="d-flex align-items-center h5 justify-content-between pt-2">
              <span>Total</span>
              <span className="price font-weight-bold">
                ₹800
              </span></p>
          </div>
        </div>


        <div className="action fixed-bottom justify-content-between bg-white d-flex align-items-center">
          <span className="d-flex btn text-primary py-1 font-weight-bold align-items-center flex-column">
            <span>Total</span>
            <span>₹80</span>
          </span>
          <button className="btn btn-primary py-2" onClick={() => {
            navigate("/payment");
          }}
          >Proceed Payment</button>
        </div>
      </div>
    </div>
  );
}
