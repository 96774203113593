import React from "react";
import Header from "../../layouts/Header";

import { Accordion, InputGroup, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faMapLocation,
  faCircle,
  faSearch,
  faStar,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Payment() {
  const navigate = useNavigate()
  return (
    <div className="page-section payment-main">
      <Header
        pageTitle="Payment"
        isBackVisible={true}
      />
      <div className="common-container">
        <div className="container">
          <h4 className="mb-3">Payment</h4>
          <div className="payment-inner mb-4">
            <div className="text-center p-3 shadow-lg rounded-lg bg-white">
              <div className="mb-4"><img src="./assets/images/check-mark.png" alt="" /></div>
              <h4 className="mb-4">Payment Successful</h4>
              <button className="btn btn-primary" onClick={() => { navigate("/previous-orders") }}>See Orders</button>
            </div>
            {/* <button className="btn btn-primary" onClick={() => { navigate("/table-reservation-success") }}>Next</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
