import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function PublicRoute(props) {
  const user = JSON.parse(localStorage.getItem("menulao-user"));
  return user && user.access_token !== undefined ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
}
