import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Modal, Button, Form, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCheckCircle,
  faCircle,
  faStar,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from 'antd';
import { useNavigate } from "react-router-dom";


export default function PackagesCustomize() {
  const navigate = useNavigate();
  const [showDecorationModal, setShowDecorationModal] = useState(false);
  const [cakeImg, setCakeImg] = useState(false);
  const [decorationImg, setDecorationImg] = useState(false);
  const [bookingEventStep, setBookingEventStep] = useState(1);

  const handleDecorationModalClose = () => setShowDecorationModal(false);
  const handleDecorationModalShow = () => setShowDecorationModal(true);

  const handleBookingEventPrevious = () => {
    if (bookingEventStep !== 1) {
      setBookingEventStep(bookingEventStep - 1)
    }
    else {
      return
    }
  }
  const handleBookingEventNext = () => {
    if (bookingEventStep !== 8) {
      setBookingEventStep(bookingEventStep + 1)
    }
    else {
      return
    }
  }

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  const handleBookingDetails = () => {
    navigate("/booked-package-details")
  }

  const handleDecorationImg = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (uploadedFileType.includes("image")) {
  
      setDecorationImg(file);

    } else {
      alert("Wrong File type encountered! Please try again");
      return;
    }
  }

  const handleCakeImg = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (uploadedFileType.includes("image")) {
      setCakeImg(file);
    } else {
      alert("Wrong File type encountered! Please try again");
      return;
    }
  }
  return (
    <div className="page-section customize-package-wrapper">
      <Header
        pageTitle="Packages"
        isBackVisible={true} />
      <div className="common-container">
        <div className="container py-4">
          <div className="bg-white rounded-lg  p-3 shadow-lg">
            <h2 className="border-bottom pb-3"> Booking Details</h2>
            <div className="py-4 border-bottom">
              {bookingEventStep === 1 ? <div className="step">
                <h3 className="mb-4 text-dark">Guests</h3>
                <ul className="h4 font-weight-medium  text-dark mb-2">
                  <li className="mb-2">
                    <label htmlFor="" className="font-weight-semi h4 text-dark mb-3">Enter Guest Count</label>
                    <input type="text" className="form-control py-3" placeholder="Enter No of Guests" />
                  </li>
                </ul>

              </div> : bookingEventStep === 2 ? <div className="step">
                <h3 className="mb-4 text-dark">Select Event Date</h3>
                <ul className="h4 font-weight-medium text-dark mb-2">
                  <li className="mb-2">
                    <div className="shadow">
                      <Calendar fullscreen={false} onPanelChange={onPanelChange} />
                    </div>
                  </li>
                </ul>
              </div> : bookingEventStep === 3 ? <div className="step">
                <h3 className="mb-4 text-dark">Tell Us About Timings</h3>
                <ul className="h4 font-weight-medium text-dark mb-2">
                  <li className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="timing"
                      className="d-flex align-items-center"
                      label={<div className="ms-3">
                        Morning - (7am to 10am)
                      </div>}
                    />
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="timing"
                      className="d-flex align-items-center"
                      label={<div className="ms-3">
                        Afternoon - (11am to 02pm)
                      </div>}
                    />
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="timing"
                      className="d-flex align-items-center"
                      label={<div className="ms-3">
                        Evening - (4pm to 7pm)
                      </div>}
                    />
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="timing"
                      className="d-flex align-items-center"
                      label={<div className="ms-3">
                        Late Evening - (7pm to 10pm)
                      </div>}
                    />
                  </li>
                  <li className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="timing"
                      className="d-flex align-items-center"
                      label={<div className="ms-3">
                        Full Day - (11pm to 7pm)
                      </div>}
                    />
                  </li>
                </ul>

              </div> : bookingEventStep === 4 ? <div className="step">
                <div className="filter d-flex align-items-center border-bottom pb-3 mb-3 justify-content-end">
                  Filter
                  <select name="" id="" className="form-select ms-4" style={{ maxWidth: "150px" }}>
                    <option value="">All</option>
                    <option value="">Veg</option>
                    <option value="">Non Veg</option>
                  </select>
                </div>
                <h3 className="mb-4 text-dark">Select 3 Veg Starters</h3>
                <ul className="h4 font-weight-medium text-dark mb-4">
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-start"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-tikka.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Paneer Tikka <br /> <span className="h6">(Best Seller)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-roll.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>                        Paneer Roll
                            <br /> <span className="h6">(Best Seller)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"

                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/spring-rolls.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Sprill Roll
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"

                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-roll.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Spicy Veg Roll
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-chilla.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span> Spicy Veg Chilla
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/soya-chaap.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>  Malai Soya Chaap
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                </ul>

                <h3 className="mb-4 text-dark">Select 3 Non Veg Starters</h3>
                <ul className="h4 font-weight-medium text-dark mb-4">
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-start"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-tikka.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Paneer Tikka <br /> <span className="h6">(Best Seller)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-roll.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>                        Paneer Roll
                            <br /> <span className="h6">(Best Seller)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"

                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/spring-rolls.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Sprill Roll
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"

                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-roll.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>Spicy Veg Roll
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/paneer-chilla.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span> Spicy Veg Chilla
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-start">
                        <span className="food-img">
                          <img src={"./assets/images/soya-chaap.jpg"} alt="" />
                        </span>
                        <div className="d-flex align-items-start">
                          <span className="border  font-smallest mt-1 me-3 text-success  border-success px-1" style={{ padding: "2px 0" }}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span>  Malai Soya Chaap
                            <br /> <span className="h6">(Recommended)</span> </span>
                        </div>
                      </div>}
                    />
                  </li>
                </ul>

              </div> : bookingEventStep === 5 ? <div className="step">
                <h3 className="mb-4 text-dark">Select 3 Liqour</h3>
                <ul className="h4 font-weight-medium text-dark mb-2">
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/100-Piper.jpg"} alt="" />
                        </span>
                        100 Pipers- whiskey
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/teachers.jpg"} alt="" />
                        </span>
                        Teachers - whiskey
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/black-dog.jpg"} alt="" />
                        </span>
                        Black Dog - whiskey
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/magic-moment.jpg"} alt="" />
                        </span>
                        Magic Moments - Vodka
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/absolut-vodka.jpg"} alt="" />
                        </span>
                        Absolut - Vodka
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/Kingfisher-Ultra-Max.jpg"} alt="" />
                        </span>
                        Kingfisher Ultra - Beer
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/bira-white.jpg"} alt="" />
                        </span>
                        Bira White - Beer
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="checkbox"
                      name="budget-range"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/budwiser.jpg"} alt="" />
                        </span>
                        Budwiser Magnum - Beer
                      </div>}
                    />
                  </li>
                </ul>
              </div> : bookingEventStep === 6 ? <div className="step">
                <h3 className="mb-4 text-dark">Select Cake</h3>
                <ul className="h4 font-weight-medium text-dark mb-2">
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/Choco-Truffle-Cake.jpg"} alt="" />
                        </span>
                        Choco Truffle Cake (eggless)
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/fresh-fruit-cake.jpg"} alt="" />
                        </span>
                        Fresh Fruit Cake (eggless)
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/pineapple-cake.jpg"} alt="" />
                        </span>
                        Special Pineapple Cake (eggless)
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/kitkat-cake.jpg"} alt="" />
                        </span>
                        Kitkat Gems Eggless Cake
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/oreo-cake.jpg"} alt="" />
                        </span>
                        Oreo Eggless Cake
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/blue-berry-cake.jpg"} alt="" />
                        </span>
                        Blue Berry Cake (eggless)
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/red-velvet-cake.jpg"} alt="" />
                        </span>
                        Red Velvet Cake (eggless)
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/butterscotch-cake.jpg"} alt="" />
                        </span>
                        Butterscotch Cake (eggless)
                      </div>}
                    />
                  </li>

                </ul>
                <Form.Group className="mb-3 my-3">
                  <Form.Label className="text-dark">Add instructions</Form.Label>
                  <Form.Control as="textarea" placeholder="Add instructions" className="font-small" rows={3} />
                </Form.Group>
                <Form.Group className="mb-3 my-3">
                  <label htmlFor="cakeImageInput" className="btn w-100 py-2 btn-primary">
                    Add Image
                    <input type="file" id="cakeImageInput" className="d-none" onChange={(e) => { handleCakeImg(e) }} />
                  </label>
                </Form.Group>
                
                {cakeImg ? <>
                  <img src={URL.createObjectURL(cakeImg)} alt="" />
                </> : null
                }
              </div> : bookingEventStep === 7 ? <div className="step">

                <h3 className="mb-4 text-dark">Select Decoration</h3>
                <ul className="h4 font-weight-medium text-dark mb-2">
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/decoration-1.jpg"} alt="" />
                        </span>
                        <div className="">
                          <p className="mb-0">Decoration Type 1</p>
                          <p><span className="text-underline font-small text-primary" onClick={handleDecorationModalShow}>View </span></p>
                        </div>
                      </div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/decoration-2.jpg"} alt="" />
                        </span>
                        <div className="">
                          <p className="mb-0">Decoration Type 2</p>
                          <p><span className="text-underline font-small text-primary" onClick={handleDecorationModalShow}>View </span></p>
                        </div></div>}
                    />
                  </li>
                  <li className="mb-2">
                    <Form.Check
                      type="radio"
                      name="cake-select"
                      className="d-flex align-items-center"
                      label={<div className="ms-3 d-flex align-items-center">
                        <span className="food-img">
                          <img src={"./assets/images/decoration-3.jpg"} alt="" />
                        </span>
                        <div className="">
                          <p className="mb-0">Decoration Type 3</p>
                          <p><span className="text-underline font-small text-primary" onClick={handleDecorationModalShow}>View </span></p>
                        </div></div>}
                    />
                  </li>
                </ul>
                <Form.Group className="mb-3 my-3">
                  <Form.Label className="text-dark">Add instructions</Form.Label>
                  <Form.Control as="textarea" placeholder="Add instructions" className="font-small" rows={3} />
                </Form.Group>
                <Form.Group className="mb-3 my-3">
                  <label htmlFor="decorationImageInput" className="btn w-100 py-2 btn-primary">
                    Add Image
                    <input type="file" id="decorationImageInput" className="d-none" onChange={(e) => { handleDecorationImg(e) }} />
                  </label>
                </Form.Group>
                {decorationImg ? <>
                  <img src={URL.createObjectURL(decorationImg)} alt="" />
                </> : null

                }
              </div> : bookingEventStep === 8 ? <div className="step text-center">

                <h3 className="mb-4 text-dark">Your Request has been sent successfully</h3>
                <div className="">
                  <FontAwesomeIcon icon={faCheckCircle} className="font-large text-primary" />
                  <p className="mt-4 text-dark">Thanks! We will get back to you soon</p>
                </div>
              </div> : null}
            </div>
            <div className="action d-flex pt-3">
              {bookingEventStep !== 1 ? <Button variant="secondary" className="py-2 flex-grow-1 me-2" onClick={handleBookingEventPrevious}>
                Previous
              </Button> : null}

              <Button variant="primary" className="py-2 flex-grow-1" onClick={bookingEventStep === 8 ? (handleBookingDetails) : handleBookingEventNext}>
                {bookingEventStep === 7 ? "Book" : bookingEventStep === 8 ? "See Details" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={`decoration-modal ${showDecorationModal ? "active" : null}`} onClick={handleDecorationModalClose}>
        <div className="inner bg-white" onClick={(e) => e.stopPropagation()}>
          <span className="close-btn text-primary h2 cursor-pointer" onClick={handleDecorationModalClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
          <Carousel>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-1.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-2.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-3.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-4.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-5.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"./assets/images/decoration-slide-6.jpg"}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>



    </div>
  );
}
