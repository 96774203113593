const dev = {
  apiUrl: process.env.REACT_APP_MENULAO_BACKEND_API_URL,

  auth: {
    signin: "/rest-auth/login/",
  },

  category: {
    categoryLists: "/menu/category/",
    createCategory: "/menu/category/",
    categoryByid: "/menu/category/:id/",
  },

  menuItems: {
    menuItemLists: "/menu/menu-item/",
    createCategoryMenuItem: "/menu/menu-item/",
    menuItemByid: "/menu/menu-item/:id/",
  },
};

const config = dev;

export default {
  ...config,
};
