import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useNavigate } from "react-router-dom";



export default function BookedPackageDetails() {
  const navigate = useNavigate();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [showChoicesModal, setShowChoicesModal] = useState(false);
  const handleChoicesModalClose = () => setShowChoicesModal(false);
  const handleChoicesModalShow = () => setShowChoicesModal(true);

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };
  const navigatePackageEditHandler = () => {
    navigate("/packages-customize");
  }
  return (
    <div className="page-section packages-booking-details">
      <Header
        pageTitle="Packages"
        isBackVisible={true} />
      <div className="common-container">
        <div className="container">

          <div className="bg-white rounded-lg shadow-lg p-3 mb-4">
            <h3 className="mb-3">Package Details</h3>

            <div className="text-dark">
              <h5 className="text-dark">No of Guests</h5>
              <p className="font-small">10 Guests</p>
              <h5 className="text-dark">Date of Event</h5>
              <p className="font-small">30 December, 2022</p>
              <h5 className="text-dark">Time of Event</h5>
              <p className="font-small">Evening - (4pm to 7pm)</p>

              <h5 className="text-dark">Cake</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/blue-berry-cake.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>Blue Berry Cake (eggless)
                    </span>
                  </div>
                </li>
              </ul>
              <h5 className="text-dark">Veg Starters</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>

              </ul>
              <h5 className="text-dark">Non Veg Starters</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>Chicken Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span> Chicken Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span> Chicken Roll
                    </span>
                  </div>
                </li>

              </ul>

              <h5 className="text-dark">Main Course Veg</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-success  border-success" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Paneer Roll
                    </span>
                  </div>
                </li>
              </ul>
              <h5 className="text-dark">Main Course Non Veg</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>Chicken Curry
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Chicken Curry
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/paneer-tikka.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="border  font-smallest mt-1 me-2 text-danger  border-danger" style={{ padding: "2px", lineHeight: 1 }}>
                      <FontAwesomeIcon icon={faCircle} />
                    </span>
                    <span>                        Chicken Curry
                    </span>
                  </div>
                </li>
              </ul>

              <h5 className="text-dark">Liqour</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/100-Piper.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="font-smallest mt-1 me-2" style={{ flex: "0 0 25px" }}>
                      <img src="./assets/images/alcoholic.png" alt="" />


                    </span>
                    <span>                    100 Pipers- whiskey
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/magic-moment.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="font-smallest mt-1 me-2" style={{ flex: "0 0 25px" }}>
                      <img src="./assets/images/alcoholic.png" alt="" />
                    </span>
                    <span> Magic Moments - Vodka
                    </span>
                  </div>
                </li>
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/budwiser.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">
                    <span className="font-smallest mt-1 me-2" style={{ flex: "0 0 25px" }}>
                      <img src="./assets/images/alcoholic.png" alt="" />
                    </span>
                    <span>Budwiser Magnum - Beer
                    </span>
                  </div>
                </li>
              </ul>


              <h5 className="text-dark">Decoration</h5>
              <ul className="mb-3 mt-2">
                <li className="d-flex mb-2">
                  <div className="food-img">
                    <img src="./assets/images/decoration-1.jpg" alt="" />
                  </div>
                  <div className="d-flex align-items-start">

                    <span>  Decoration Type 1
                    </span>
                  </div>
                </li>

              </ul>
            </div>
            <div className="action mt-2 text-center border-top pt-3">
              <button className="btn btn-primary py-2 w-100 mb-2" onClick={navigatePackageEditHandler}>Edit </button>
              <p className="font-smallest fst-italic">* Package Cannot be updated 24hours before party</p>
            </div>
          </div>


        </div>
      </div>



    </div>
  );
}
