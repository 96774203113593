import React from "react";
import Header from "../../layouts/Header";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function TableReservation() {

  const navigate = useNavigate();

  return (
    <div className="page-section table-reservation-page">
      <Header />
      <div className="common-container">
        <div className="container">

          <div className="food-list">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>

                  Common Section (6Tables)
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-6">
                      <div className="table-box text-center available-table"
                      onClick={() => {
                        navigate("/timing-selection");
                      }}
                      >
                        <h4 className="table-no">Table <br /> 1</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 3</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 4</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                   
                  </div>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Family Section (6Tables)

                </Accordion.Header>
                <Accordion.Body>
                     <div className="row">
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                    
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                   
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Couple Section (6Tables)

                </Accordion.Header>
                <Accordion.Body>
                <div className="row">
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 4</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                    
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 3</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 1</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                   
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Get Together Section (6Tables)

                </Accordion.Header>
                <Accordion.Body>
                <div className="row">
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                    
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                   
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Corporate Meeting Section (6 Tables)

                </Accordion.Header>
                <Accordion.Body>
                <div className="row">
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                    
                    <div className="col-6">
                      <div className="table-box text-center available-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Available</p>
                      </div>
                    </div>
                  
                    <div className="col-6">
                      <div className="table-box text-center occupied-table">
                        <h4 className="table-no">Table <br /> 2</h4>
                        <p className="capacity">Capacity: 8</p>
                        <p className="availablity">Occupied</p>
                      </div>
                    </div>
                   
                  </div>
                </Accordion.Body>
              </Accordion.Item>


            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
