import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { user_token } from "./_helpers/tokens/UserToken";
import { routes } from "./routes/routes";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { SignIn, WelcomeScreen, OTP } from "./pages/Auth";
import Dashboard from "./pages/Dashboard";

import RestaurantList from "./pages/RestaurantList";
import FoodMenu from "./pages/FoodMenu";
import RestaurantAction from "./pages/RestaurantAction";
import TableReservation from "./pages/TableReservation";
import Cart from "./pages/Cart";
import FinalBillPreview from "./pages/FinalBillPreview";
import Profile from "./pages/Profile";
import PreviousOrders from "./pages/PreviousOrders";
import Payment from "./pages/Payment";
import ViewOrderDetails from "./pages/ViewOrderDetails";
import OrderPlaced from "./pages/OrderPlaced";
import TimingSelection from "./pages/TimingSelection";
import TableReservationSuccess from "./pages/TableReservationSuccess";
import PackagesList from "./pages/PackagesList";
import PackagesDetails from "./pages/PackagesDetails";
import PackagesCustomize from "./pages/PackagesCustomize";
import BookedPackageDetails from "./pages/BookedPackageDetails";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={user_token === null ? <RestaurantAction /> : <Dashboard />}
        />
        <Route path="/" element={<PublicRoute />}>
          <Route path={routes.public.welcomeScreen} element={<WelcomeScreen />} />
          <Route path={routes.public.signin} element={<SignIn />} />
          <Route path={routes.public.OTP} element={<OTP />} />
          <Route path={routes.public.RestaurantList} element={<RestaurantList />} />
          <Route path={routes.public.FoodMenu} element={<FoodMenu />} />
          <Route path={routes.public.RestaurantAction} element={<RestaurantAction />} />
          <Route path={routes.public.TableReservation} element={<TableReservation />} />
          <Route path={routes.public.Cart} element={<Cart />} />
          <Route path={routes.public.FinalBillPreview} element={<FinalBillPreview />} />
          <Route path={routes.public.Profile} element={<Profile />} />
          <Route path={routes.public.PreviousOrders} element={<PreviousOrders />} />
          <Route path={routes.public.Payment} element={<Payment />} />
          <Route path={routes.public.OrderPlaced} element={<OrderPlaced />} />
          <Route path={routes.public.TimingSelection} element={<TimingSelection />} />
          <Route path={routes.public.TableReservationSuccess} element={<TableReservationSuccess />} />
          <Route path={routes.public.PackagesList} element={<PackagesList />} />
          <Route path={routes.public.PackagesDetails} element={<PackagesDetails />} />
          <Route path={routes.public.PackagesCustomize} element={<PackagesCustomize />} />
          <Route path={routes.public.BookedPackageDetails} element={<BookedPackageDetails />} />
          {/* <Route path={routes.public.FinalBillPreview} element={<FinalBillPreview />} /> */}
          {/* <Route path={routes.public.FinalBillPreview} element={<FinalBillPreview />} /> */}
          {/* <Route path={routes.public.FinalBillPreview} element={<FinalBillPreview />} /> */}
          {/* <Route path={routes.public.FinalBillPreview} element={<FinalBillPreview />} /> */}
        </Route>
        <Route path="/" element={<PrivateRoute />}>

        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
