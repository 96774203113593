import React from "react";
import Header from "../../layouts/Header";
export default function Dashboard() {
  return (
    <div className="admin-dashboard-section">
     
      <div className="dashboard-main-content">
        <Header pageTitle="Dashboard" />
      </div>
    </div>
  );
}
