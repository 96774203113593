import React, { useState } from "react";
import Header from "../../layouts/Header";

import { Accordion, InputGroup, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import {
  faLocationDot,
  faArrowRight,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function TableReservationSuccess() {
  const navigate = useNavigate();
  return (
    <div className="page-section user-orders-main">
      <Header />
      <div className="common-container">
        <div className="container pt-5">
          <div className="text-center border shadow p-3 rounded ">
            <div className="img">
              <img src={"./assets/images/restaurant.png"} alt="resturant" width="150px" />
            </div>
            <h3 className="text-success mb-4">Your table has been booked</h3>
            <p>Note: Please order 30 min before to confirm your booking </p>
          </div>
        </div>
      </div>
    </div>
  );
}
